import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello there!</h1>
        <p>Website sometime coming in the future....</p>
      </header>
    </div>
  );
}

export default App;
